<template>
  <div class="class-ticket-record">
    <PageTitle
      title="堂票紀錄列表"
      btn="匯出新增紀錄"
      btn2="匯出使用紀錄"
      @btnClick=";(exportType = 'add'), (dialog.export = true)"
      @btn2Click=";(exportType = 'use'), (dialog.export = true)"
    />

    <FiltersContainer>
      <MemberSearch
        :model.sync="member"
        @change="refresh"
        @clear="refresh(true)"
      />
      <el-input
        v-model="search.code"
        style="max-width: 400px"
        clearable
        placeholder="搜尋堂票編號"
        @keypress.enter.native="refresh(true)"
        @clear="refresh(true)"
      >
        <i
          slot="prefix"
          class="el-input__icon el-icon-search"
          @click="refresh(true)"
        />
      </el-input>
      <ClassTicketSearch
        :model.sync="search.classTicket"
        objKey="id"
        @change="refresh(true)"
        @clear="refresh(true)"
      />
    </FiltersContainer>

    <section>
      <el-table
        v-loading="loading"
        :data="classTicketRecordList"
        empty-text="暫無數據"
      >
        <EmptyBlock slot="empty" />
        <el-table-column
          prop="code"
          label="堂票編號"
          fixed="left"
          width="115"
          align="center"
        />
        <el-table-column
          prop="name"
          label="堂票名稱"
          width="115"
          align="center"
        />
        <el-table-column prop="status" label="狀態" align="center" width="110">
          <template slot-scope="scope">
            <Tag :type="classTicketTagType(scope.row.status)">
              {{ statusLabel(scope.row) }}
            </Tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="Member.UserInfo.name"
          label="姓名"
          fixed="left"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.Member ? scope.row.Member.UserInfo.name : '非會員' }}
          </template>
        </el-table-column>
        <el-table-column label="可用張數" align="center">
          <template slot-scope="scope">
            {{ scope.row.availableTimes }}
          </template>
        </el-table-column>
        <el-table-column label="已用張數" align="center">
          <template slot-scope="scope">
            {{ scope.row.usedTimes }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="useTicketPermission"
          label="已贈張數"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.giveTimes }}
          </template>
        </el-table-column>
        <el-table-column prop="price" label="價格" align="center" />
        <el-table-column
          prop="origin"
          label="新增來源"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            {{ mapOrigin(scope.row.origin) }}
          </template>
        </el-table-column>
        <!-- <el-table-column
          v-if="useStorePermission"
          prop="Branch"
          label="操作單位"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.Branch?.name || '-' }}
          </template>
        </el-table-column> -->
        <el-table-column prop="isExp" label="有效期限" align="center">
          <template slot-scope="scope">
            <Tag disable-transitions :type="tagType(scope.row.isExp)">
              {{ scope.row.isExp ? '有' : '無' }}
            </Tag>
          </template>
        </el-table-column>
        <el-table-column prop="exp" label="有效日期" width="120" align="center">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.exp) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="note"
          label="備註"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.note || '-' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="createdAt"
          label="建立時間"
          width="120"
          align="center"
        >
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createdAt) }}
          </template>
        </el-table-column>

        <el-table-column label="操作" fixed="right" align="center" width="100">
          <template slot-scope="scope">
            <TableEditBtnGroup
              v-if="scope.row.Member"
              editBtn="前往會員"
              hideDelete
              @edit="goToProfile(scope.row)"
            />
            <!-- <div v-if="scope.row.Member" class="table-edit-btn-group">
              <el-button type="text" class="table-opt-edit" @click="goToProfile(scope.row)">前往會員</el-button>
            </div> -->
            <div v-else>-</div>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="classTicketRecordCount"
        @pageChange="refresh(false)"
      />
    </section>

    <ExportOptionsDialog
      v-if="dialog.export"
      allRange
      @close="dialog.export = false"
      @export=";(dialog.export = false), prepareExport()"
    />
    <ExportDialog
      v-if="showExportDialog"
      :inProgress="exportting"
      :isError="exportError"
      :percentage="exportPercentage"
      :data="exportData"
      :total="exportTotal"
      @close="resetExport"
    />
  </div>
</template>

<script>
import { defineComponent, reactive } from 'vue'
import { mapGetters } from 'vuex'
import MemberSearch from '@/components/Search/MemberSearch.vue'
import ClassTicketSearch from '@/components/Search/ClassTicketSearch.vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import ExportOptionsDialog from '@/components/Dialog/ExportOptionsDialog.vue'
import ExportDialog from '@/components/Dialog/ExportDialog.vue'
import {
  GetClassTicketRecord,
  GetClassTicketRecordCount,
  GetClassTicketUseRecord,
  GetClassTicketUseRecordCount,
  GetClassTicketRecordSerial,
} from '@/api/classTicket'
import { pageStartIndex } from '@/utils/table'
import { checkUserFeature } from '@/store/modules/permission'
import MixinFunc from '@/components/MixinFunc'
import ExportMixin from '@/mixin/export'
import { getAllDataFromApi } from '@/utils/helper'
import { get, map, join } from 'lodash'
import {
  classTicketUseRecordOriginConfig,
  classTicketUseRecordTypeConfig,
} from '@/config/classTicket'
import { ExportMoreSheetExcel } from '@/utils/excel'

export default defineComponent({
  name: 'ClassTicketRecord',
  components: {
    MemberSearch,
    EmptyBlock,
    ClassTicketSearch,
    ExportOptionsDialog,
    ExportDialog,
  },
  mixins: [MixinFunc, ExportMixin],
  setup () {
    const search = reactive({
      code: '',
      classTicket: '',
    })

    return { search, ExportMoreSheetExcel }
  },
  data: () => ({
    member: '',
    loading: false,
    showDialog: false,
    dialogType: 'create',
    deleteDialog: false,
    exportType: 'add',
    dialog: {
      export: false,
    },
    warning: {
      type: null,
      error: null,
      total: 0,
      success: 0,
      fail: 0,
    },
    processing: {
      task: null,
      detail: null,
      title: '',
      inProgress: false,
      isError: false,
      percentage: 0,
    },

    classTicketRecordCount: 0,
    classTicketRecordList: [],

    tableOptions: {
      page: 1,
      pageLimit: 10,
    },
  }),

  computed: {
    ...mapGetters(['shop', 'userFeatures', 'userPlanFeature']),
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },
    mapOrigin () {
      const origins = {
        admin: '會員後台',
        sales: '銷售紀錄',
        memberStore: '會員商城',
        eventPlaybook: '系統行銷活動發放',
      }
      return (key) => origins[key] || '-'
    },
    useStorePermission () {
      return checkUserFeature(
        this.userPlanFeature,
        this.userFeatures,
        'admin.branch.adminUseStore',
      )
    },
    useTicketPermission () {
      return checkUserFeature(
        this.userPlanFeature,
        this.userFeatures,
        'admin.classTicketConfig.clientGive',
      )
    },
  },
  async mounted () {
    await this.refresh()
  },

  methods: {
    openExportDialog () {
      if (!this.filteredExportFormatOptions.length) {
        return this.$message.error('無此權限')
      } else {
        this.exportFormData.format = this.filteredExportFormatOptions[0].value
      }

      this.showExportDialog = true
    },
    classTicketTagType (val) {
      let type = 'info'
      if (val === 'open') type = 'action'
      if (val === 'cancel') type = 'danger'
      return type
    },
    statusLabel (data) {
      if (data.usedTimes || data.availableTimes) {
        if (data.usedTimes === data.availableTimes) return '使用完畢'
      }
      if (data.status === 'overdue') return '已過期'
      else if (data.status === 'open') return '可使用'
      else if (data.status === 'complete') return '使用完畢'
      else if (data.status === 'cancel') return '已作廢'
    },
    tagType (val) {
      let type = 'info'
      // if(! val) type = 'danger'
      if (val) type = 'action'
      return type
    },
    async recordSerial () {
      const [res, err] = await GetClassTicketRecordSerial({
        shopId: this.shop,
        start: 0,
        limit: 100,
      })
      if (err) this.$message.error(err)
      return res
    },

    openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },

    async refresh (resetPage) {
      if (resetPage) this.tableOptions.page = 1
      this.loading = true
      await this.getClassTicketRecord()
      await this.getClassTicketRecordCount()
      this.loading = false
    },

    goToProfile (row) {
      this.$router.push(`/members/member-profile/${row.MemberId}/classTicket`)
    },

    async getClassTicketRecord () {
      try {
        const res = await GetClassTicketRecord({
          shopId: this.shop,
          start: this.pageStartIndex,
          limit: this.tableOptions.pageLimit,
          code: this.search.code || undefined,
          MemberId: this.member ? this.member.id : undefined,
          ClassTicketId: this.search.classTicket || undefined,
        })

        this.classTicketRecordList = res
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    async getClassTicketRecordCount () {
      try {
        this.classTicketRecordCount = await GetClassTicketRecordCount({
          shopId: this.shop,
          code: this.search.code || undefined,
          MemberId: this.member ? this.member.id : undefined,
          ClassTicketId: this.search.classTicket || undefined,
        })
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    // ----------- Export -----------
    async getExportData () {
      let fetchApi = GetClassTicketRecord
      if (this.exportType === 'use') fetchApi = GetClassTicketUseRecord
      const payload = {
        shopId: this.shop,
        start: 0,
        limit: 100,
        search: this.search.all || undefined,
        MemberId: get(this.search, 'member.id') || undefined,
      }

      return await getAllDataFromApi(this.exportTotal, fetchApi, payload)
    },
    resetProcessing () {
      this.processing.task = null
      this.processing.title = ''
      this.processing.percentage = 0
      this.processing.isError = false
      this.processing.inProgress = false
    },
    async prepareExport () {
      this.resetExport()
      // 取得匯出數據數量
      let fetchApi = GetClassTicketRecordCount
      if (this.exportType === 'use') fetchApi = GetClassTicketUseRecordCount

      const res = await fetchApi({
        shopId: this.shop,
        search: this.search.all || undefined,
        MemberId: get(this.search, 'member.id') || undefined,
      })
      this.exportTotal = res
      if (!this.exportTotal) {
        this.$message.warning('沒有資料可以匯出')
        return
      }
      this.showExportDialog = true
      this.exportting = true
      try {
        const data = await this.getExportData()
        this.exportData = data
        await this.formatExportData()
        this.exportting = false
      } catch (error) {
        console.log(error)
        this.exportting = false
        this.exportError = true
        this.$message.error(error)
      }
    },
    async formatAddRecordData (item) {
      return {
        堂票編號: item.code,
        姓名: get(item, 'Member.UserInfo.name') || '非會員',
        堂票名稱: item.name,
        狀態: this.statusLabel(item),
        可用張數: item.availableTimes,
        已用張數: item.usedTimes,
        已贈張數: item.giveTimes,
        價格: item.price,
        新增來源: this.mapOrigin(item.origin),
        有效期限: item.isExp ? '有' : '無',
        有效日期: this.dateFormat(item.exp),
        備註: item.note,
        建立時間: this.dateFormat(item.createdAt),
      }
    },
    async formatAddRecordSerialData (item) {
      return {
        堂票序號編號: get(item, 'code'),
        堂票序號狀態: this.statusLabel(item),
        會員姓名: get(item, 'ClassTicketRecord.Member.UserInfo.name') || '非會員',
        堂票編號: get(item, 'ClassTicketRecord.code'),
        堂票名稱: get(item, 'ClassTicketRecord.name'),
        堂票狀態: this.statusLabel(get(item, 'ClassTicketRecord')),
        有效期限: get(item, 'ClassTicketRecord.isExp') ? '有' : '無',
        有效日期: this.dateFormat(get(item, 'ClassTicketRecord.isExp')),
        新增來源: this.mapOrigin(get(item, 'ClassTicketRecord.origin')),
        是否履約: get(item, 'TicketPaymentPromise.ticketPromiseRecordId') ? '是' : '否',
        // 是否已送履約核銷: '-',
        建立時間: this.dateFormat(item.createdAt),
      }
    },
    formatUseRecordData (item) {
      return {
        使用時間: this.dateFormat(item.createdAt),
        堂票名稱: get(item, 'ClassTicketRecord.name'),
        堂票編號: get(item, 'ClassTicketRecord.code'),
        使用來源: get(classTicketUseRecordOriginConfig, `${item.origin}.label`),
        使用門市: get(item, 'Branch.name'),
        會員名稱:
          get(item, 'ClassTicketRecord.Member.UserInfo.name') || '非會員',
        使用類型: get(classTicketUseRecordTypeConfig, `${item.type}.label`),
        '使用/取消': item.isCancelType ? '取消' : '使用',
        '使用/取消次數': Math.abs(item.times),
        堂票序號陣列: join(
          map(item.ClassTicketRecordSerials, 'code'),
          ',',
        ),
      }
    },
    async formatExportData () {
      const data = []
      const serialRecordData = []
      let count = 0

      if (this.exportType === 'add') {
        const recordSerialData = await this.recordSerial()
        for (const subitem of recordSerialData) {
          const row = await this.formatAddRecordSerialData(subitem)
          serialRecordData.push(row)
        }
      }
      for (const item of this.exportData) {
        let row
        if (this.exportType === 'add') {
          row = await this.formatAddRecordData(item)
        }
        if (this.exportType === 'use') {
          row = this.formatUseRecordData(item)
        }
        data.push(row)
        count++
        this.exportPercentage = (count / this.exportTotal) * 100
      }
      let fileName
      if (this.exportType === 'add') {
        const sheets = [
          {
            sheetName: '堂票新增紀錄',
            data: data,
          },
          {
            sheetName: '堂票序號新增紀錄',
            data: serialRecordData,
          },
        ]
        fileName = '堂票新增紀錄'
        this.ExportMoreSheetExcel(sheets, fileName)
      }
      if (this.exportType === 'use') {
        fileName = '堂票使用紀錄'
        this.ExportExcel(data, fileName, fileName)
      }
    },
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-input {
  @apply w-full max-w-[400px];
}
</style>
